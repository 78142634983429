.account {
  .react-tabs {
    &__tab {
      width: calc(100% / 3);
      text-align: center;
      color: $blue;     
      border: none;
      background: #F9F9F9;

      .active {
        display: none;
      }

      .not_active {
        display: inline-block;
        color: $dark_gray;
      }
  
      &-panel {
        padding-top: 15px;
      }

      p {
        font-size: 18px;
        color: $dark_gray;
        
      }
  
      &--selected {
        background: #F9F9F9;
        border-radius: 0;
        color: $blue;

        .active {
          display: inline-block;
        }
  
        .not_active {
          display: none;
        }

        p {
          text-decoration: underline;
          text-underline-offset: 1px;
          color: $blue;
        }
      }
  
      &-list {
        position: fixed;
        bottom: 0;
        left: 50%;
        max-width: 400px;
        transform: translateX(-50%);
        margin: 0;
        border: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  // .balancebg {
  //   padding: 10px;
  //   background: #fff;
  //   border-radius: 20px;
  //   margin-bottom: 30px;

  // }
  
  // .balance {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   justify-content: space-around;
  //   border: 2px solid $blue;
  //   border-radius: 14px;
  //   text-align: center;
  
  //   &__percent {
  //     border-right: 2px solid $blue;
  //     padding: 10px 30px 10px 10px;
  //   }
  
  //   &__value {
  //     padding: 10px;
  //   }
  
  //   &__text {
  //     color: $blue;
  //     font-size: 12px;
  //     font-weight: bold;
  
  //     &--big {
  //       font-size: 30px;
  //     }
  //   }
  // }

  .balance {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    color: $blue;

    &__value {
      background: #fff;
      border-radius: 14px;
      padding: 7px 15px 15px;
    }

    &__text {
      font-size: 20px;
      line-height: 1;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 39px;

      .refresh_balance {
        color: $blue;
        outline: none;
        background-color: #fff;
        border: none;
        box-shadow: none;
        padding: 0;

        &--rotating {
          animation: rotating 2s linear infinite;
        }
      
        &:hover {
          outline: none;
          background-color: #fff;
          box-shadow: none;
        }
      
        &:focus {
          outline: none;
          background-color: #fff;
          box-shadow: none;
        }
      }
    }

    &__data {
      font-size: 38px;
      font-weight: bold;
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }
  
  .code {
    background: #fff;
    padding: 30px 10px 10px 10px;
    border-radius: 20px;
  
    &__image {
      width: 200px;
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
    }
  
    &__text {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
  
  .sbp {
    display: flex;
    flex-direction: row;
    background: #1d1346;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    border-radius: 14px;
    border: none;
  
    img {
      width: 45px;
    }

    &__text {
      margin-right: 15px;
      padding-right: 15px;
      border-right: 1px solid #fff;
    }
  }

  .sbp:disabled, .sbp[disabled]{
    filter: grayscale(1);
  }

  .sbpLink {
    display: block;
    text-align: center;
    margin-top: 30px;
    text-decoration: underline;
    text-underline-position: unset;
    font-weight: bold;

    &:hover {
      color: $blue;
    }

    &:focus {
      color: $blue;
    }

    svg {
      margin-left: 10px;
    }
  }
  
  .enter_sum {
    background: #fff;
    padding: 10px;
    margin-bottom: 50px;
    border-radius: 14px;

    &__container {
      position: relative;

      svg {
        position: absolute;
        right: 7px;
        bottom: 16px;
        color: $blue;
      }
    }
  
    p {
      margin: 0 0 15px 0;
      color: $blue;
    }
  
    input {
      font-size: 30px;
      color: $blue;
      border-bottom: 1px solid $blue;
      border-radius: 0;
    }
  }
}

.purchasebg {
  background: #fff;
  padding: 10px;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .account {
    .react-tabs {
      &__tab {    
        &-list {
          max-width: 100%;
          background: #fff;
          box-shadow: 0px -4px 5px 1px rgba(34, 60, 80, 0.14);
        }

        p {
          margin-bottom: 0;
          font-size: 14px;
          
        }
      }
    }
  }
}

@media screen and (max-width:1024px) and (orientation:landscape) {
  .account {
    .react-tabs {
      &__tab {
        &-panel {
          padding-top: 0;
        }
      }
    }

    h2 {
      display: none;
    }

    // .balance {
    //   display: none;
    // }

    .code {
      margin-bottom: 90px;
    }
  }
}


@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}