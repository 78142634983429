.text {
  font-size: 16px;

  &--sm {
    font-size: 11px;
  }

  &--blue {
    color: $blue;
  }

  &--bold {
    font-weight: bold;
  }
}

h2 {
  color: $blue;
  font-size: 14px;
  text-align: center;
  border: 2px solid $blue;
  padding: 5px;
  border-radius: 50px;
  margin: 0 0 20px 0;
}

.plus {
  color: #03cb13;
}