.w_row {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--no-mb {
    margin-bottom: 0;
  }
}

.mb {
  margin-bottom: 15px;

  &-50 {
    margin-bottom: 50px;
  }
}

.w_container {
  width: 100%;
  padding: 0 15px;
  max-width: 430px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .w_container {
    max-width: 100%;
    padding: 0 10px;
  }
}