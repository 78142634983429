.react-date-picker {
  width: 100%;
  text-align: center;

  &__wrapper {
    background: #fff;
    border: none !important;
    border-radius: 30px;
    padding: 4px;
  }

  &__inputGroup {
    padding: 0 2px 0 63px !important;
  }

  input {
    color: $gray_text;
  }

  svg {
    stroke: $gray_text;
  }
}