@import "~bootstrap/scss/bootstrap";
// @import '~bootstrap/dist/css/bootstrap.min.css';
@import "~flag-icon-css/css/flag-icons.min.css";

@import "css/variables";
@import "css/text";
@import "css/form";
@import "css/datePicker";
@import "css/grid";
@import "css/header";

//Pages
@import "css/account";


@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro.eot');
  src: url('fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('fonts/GothamPro.woff2') format('woff2'),
  url('fonts/GothamPro.woff') format('woff'),
  url('fonts/GothamPro.ttf') format('truetype'),
  url('fonts/GothamPro.svg#GothamPro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Bold.eot');
  src: url('fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/GothamPro-Bold.woff2') format('woff2'),
  url('fonts/GothamPro-Bold.woff') format('woff'),
  url('fonts/GothamPro-Bold.ttf') format('truetype'),
  url('fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gotham Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $gray;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: $blue;
}

.logo {
  margin-bottom: 50px;

  p {
    font-size: 30px;
    color: $blue;
  }
}

.register {
  text-align: center;
  border: 2px solid $blue;
  border-radius: 20px;
  padding: 5px 0;
  width: 63%;
  margin: 0 auto;
}

.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: end;

  &-toggle {
    &:after {
      color: $blue;
    }
  }
}
