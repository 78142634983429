.header {
  padding: 15px 0;
  margin-bottom: 20px;
}

@media screen and (max-width:1024px) and (orientation:landscape) {
  .header {
    padding: 0;
    margin-bottom: 10px;
  }
}