.w_input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  text-align: center;
  color: $gray_text;
  outline: $blue;
  border: 2px solid transparent;

  &::placeholder {
    color: $gray_text;
  }

  &--warn {
    border: 2px solid #f15353;
  }
}

.w_checkbox {
  width: 34px;
  height: 19px;
  position: absolute;
  opacity: 0;

  + div {
    color: #fff;
    background: #fff;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 19px;
    border: 1px solid $blue;
    border-radius: 20px;
    margin-right: 8px;
  }

  &:checked {
    + div {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-color: $blue;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.w_label {
  display: flex;
}

.w_button {
  width: 80%;
  padding: 8px;
  font-size: 16px;
  border-radius: 30px;
  background: $blue;
  color: #fff;
  margin: 0 auto;
}

.w_form {
  &--center {
    text-align: center;
  }
}

.w_table {
  width: 100%;
  text-align: center;
  border: 1px solid $blue;
  border-collapse: collapse;

  border-radius: 10px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #666;

  th, td {
    border: 1px solid $blue;
    padding: 5px;
  }
}


.w_form {
  &__error {
    color: #f15353;
    margin-top: 20px;
    text-align: center;
  }
}